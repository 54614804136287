/**
 * Add global route middleware to protect pages using:
 *
 * definePageMeta({
 *  auth: true
 * })
 */

import type { Session } from '@supabase/supabase-js'

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error) {
    return {}
  }

  // Create a ref to know where to redirect the user when logged in
  const redirectTo = useState<string | undefined>('authRedirect')

  // This runs on navigation and checks if user is allowed to go there
  addRouteMiddleware(
    'auth',
    async (to) => {
      if (to.meta.adminAuth || to.meta.userAuth) {
        redirectTo.value = to.path

        const client = useTypedSupabaseClient()
        const session = (await client.auth.getSession()).data.session
        if (!session) {
          return '/login'
        }

        if (to.meta.adminAuth) {
          const sessionType = await getSessionType(session, client)

          if (sessionType === 'NOT_ADMIN_SESSION') {
            return '/admin/notAdminAccount'
          }
          else if (sessionType === 'NON_APPROVED_ADMIN_SESSION') {
            return '/admin/notApprovedAdmin'
          }
          else if (sessionType === 'ADMIN_SESSION') {
            // Returning nothing means continue without a redirect
          }
        }
      }
    },
    { global: true },
  )

  const currentRoute = useRoute()
  // This continously monitors the auth state and reacts, when that changes, e.g. the user logs out
  useTypedSupabaseClient().auth.onAuthStateChange(async (event) => {
    if (event === 'SIGNED_IN') {
      if (currentRoute.path.endsWith('/otp') || currentRoute.path.endsWith('/confirm')) {
        await navigateTo(redirectTo.value || '/')
        redirectTo.value = undefined
      }
    }
    else if (event === 'SIGNED_OUT') {
      if (currentRoute.meta.adminAuth || currentRoute.meta.userAuth) {
        redirectTo.value = currentRoute.path
        await navigateTo('/login')
      }
    }
  })

  return {
    provide: {
      auth: {
        redirectTo: redirectTo.value,
      },
    },
  }
})

async function getSessionType(session: Session, client: TypedSupabaseClient): Promise<'ADMIN_SESSION' | 'NON_APPROVED_ADMIN_SESSION' | 'NOT_ADMIN_SESSION'> {
  const adminProfile = await client.from('admin_profiles').select().eq('id', session.user.id).single()
  if (!adminProfile.data) {
    return 'NOT_ADMIN_SESSION'
  }
  else if (!adminProfile.data.approved) {
    return 'NON_APPROVED_ADMIN_SESSION'
  }
  return 'ADMIN_SESSION'
}
