
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "umami": {
    "id": "",
    "host": "",
    "version": 2,
    "domains": "",
    "debug": false,
    "autoTrack": true,
    "useDirective": true,
    "customEndpoint": "/",
    "ignoreLocalhost": false
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/app.config.ts"
import cfg1 from "/app/node_modules/nuxt-umami/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
