<script setup lang="ts">
import { changeLocale } from '@formkit/vue'
import { useI18n } from 'vue-i18n'
import '@fontsource/roboto-condensed/300.css'
import '@fontsource/roboto-condensed/500.css'
import '@fontsource/roboto-flex'

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [
    ...(i18nHead.value.link || []),
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', href: '/favicon.svg', sizes: 'any', type: 'image/svg+xml' },
    { rel: 'icon', href: '/favicon-32x32.png', sizes: '32x32', type: 'image/png' },
    { rel: 'icon', href: '/favicon-16x16.png', sizes: '16x16', type: 'image/png' },
    { rel: 'manifest', href: '/site.webmanifest' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
  ],
  meta: [
    ...(i18nHead.value.meta || []),
    { name: 'apple-mobile-web-app-title', content: 'BedExchange' },
    { name: 'application-name', content: 'BedExchange' },
    { name: 'msapplication-TileColor', content: '#da532c' },
    { name: 'theme-color', content: '#ffffff' },
  ],
  title: 'BedExchange',
})

const { locale } = useI18n()
watch(locale, newLocale => changeLocale(newLocale), { immediate: true })
</script>

<template>
  <div class="flex flex-col min-h-screen max-w-screen">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
