import type { RouterOptions } from '@nuxt/schema'
import { sleep } from '~/api/sleep'

export default <RouterOptions>{
  scrollBehavior: async (to, _, savedPosition) => {
    if (to.path === '/admin/matching') {
      await sleep(500)
      return savedPosition
    }
    if (to.path.startsWith('/admin/matching/'))
      return { top: 0, left: 0 }
  },
}
